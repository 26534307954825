import * as React from "react";

const Footer = () => {
    const date = new Date();
    return (
        <footer className="page-footer small text-white">
            <p className="text-center">
                Site Design, content & Logo © {date.getFullYear()} mikeamara.com
      </p>
        </footer>
    );
};

export default Footer;
