import React from "react";
import { render } from "react-dom";
import Routes from "./Routes";
import { Helmet } from "react-helmet";
import "./styles.css";

function App() {
    return (
        <>
            <Helmet>
                <title>Welcome to Mike Amara's Blog</title>
            </Helmet>
            <Routes />
        </>
    )
}

const rootElement = document.getElementById("root");
render(<App />, rootElement);
