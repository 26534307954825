import { dashboardData } from "./dashboardJson";
const server = window.location.hostname === 'http://localhost' ? 'http://www.mikeamara.com' : '';

export interface Icomment {
    createdAt: string;
    message: string;
    parentMsgId: string;
    postId: string;
    updatedAt: string;
}

export interface Idashboard {
    pageName: string;
    date: string;
    ip: string;
    env: string;
}

export const fetchAllCommentsByPostId = async (postId: string) => {
    return await fetch(`${server}/api/read/message.php?postId=${postId}`).then(r => r.json())
}

export const fetchDashboardData = async () => {
    if (window.location.hostname === "localhost") {
        return dashboardData;
    }
    return await fetch(`${server}/api/read/page-view.php`).then(r => r.json());
}

export const recordPageVisit = async (page: string, ip: string) => {
    const env = window.location.hostname;
    const dateTime = new Date();
    return await fetch(`${server}/api/create/page-view.php?page=${page}&ip=${ip}&env=${env}&date=${dateTime}`).then(r => r.json())
}

export const getClientIPAddress = async () => {
    return await fetch(`${server}/api/read/ip-address.php`).then(r => r.text())
}