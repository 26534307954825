import * as React from "react";
import { Link } from "react-router-dom";
import posts from "./posts";

interface TargetWithValue extends EventTarget {
    value?: string;
}

const Posts = () => {
    const [renderedPosts, setRenderedPosts] = React.useState(posts);
    const urlParams = new URLSearchParams(window.location.search);
    const searchBlog = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        const searchTerm = target && target.value.trim() || '';
        if (searchTerm) {
            const searchRegEx = new RegExp(searchTerm, "gmi");
            const searchResults = posts.filter(post => {
                const tags = new Set(post.tags);
                const tagsList = Array.from(tags).reduce((r, v) => {
                    return `${r} ${v}`;
                }, "");
                return !!(
                    post.title.match(searchRegEx) !== null ||
                    post.description.match(searchRegEx) !== null ||
                    tagsList.match(searchRegEx) !== null ||
                    post.id.match(searchRegEx) !== null
                );
            });
            setRenderedPosts(searchResults);
        } else {
            setRenderedPosts(posts);
        }
    };

    return (
        <>
            <div className="search-blog input-group mb-5 mx-auto">
                <input
                    type="text"
                    className="form-control"
                    aria-label="Search Blog"
                    placeholder="Search Blog..."
                    onChange={searchBlog}
                />
                <div className="input-group-append">
                    <span className="input-group-text">Search</span>
                </div>
            </div>
            {renderedPosts.map(post => {
                return (
                    <div key={post.id} className="alert" role="alert">
                        <Link to={`./${post.folderName}${urlParams.get('status') === 'notrack' ? '?status=notrack' : ''}`}>
                            <h4 className="alert-heading">{post.title}</h4>
                        </Link>
                        <p>
                            {`${post.description &&
                                post.description
                                    .split(" ")
                                    .slice(0, 25)
                                    .join(" ")}...` || ""}
                        </p>
                        <hr />
                    </div>
                );
            })}
        </>
    );
};

export default Posts;
