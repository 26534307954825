import * as React from "react";
import { Link } from "react-router-dom";

const Nav = () => {
    const [showNav, toggleNav] = React.useState(false);
    const handleToggleNav = React.useCallback(() => {
        toggleNav(!showNav);
    }, [showNav, toggleNav]);
    return (
        <nav className="page-nav navbar-expand-xl">
            <div className="navbar navbar-expand-lg navbar-dark">
                <button onClick={handleToggleNav} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <Link className="logo nav-link active m-auto" to="/"><img alt="mikeamara.com" src={"img/mainv.png"} style={{ width: 50 }} /></Link>

                <div style={{ display: showNav ? 'block' : 'none' }} className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav m-auto">
                        <li className="nav-item">
                            <Link className="nav-link active" to="/">
                                Posts
                            </Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link className="nav-link active" to="/devsbank">
                                Dev's Bank
                            </Link>
                        </li> */}
                        <li className="nav-item">
                            <Link className="nav-link active" to="/about">
                                About
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Nav;
