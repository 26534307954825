import * as React from "react";
import GenericTemplate from "./Template";
import { Idashboard, fetchDashboardData } from "../services";
import { BarChart, BarSeries, Bar, BarLabel } from "reaviz";

interface IdataByPageName {
    pageName: string;
    count: number;
}

interface IdataReaviz {
    key: string;
    data: number;
}

const getDayOfWeek = (date: string) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const d = new Date(date);
    return days[d.getDay()];
}

const getMMDDYYYY = (_date: string) => {
    const date = new Date(_date);
    return `${(date.getMonth() + 1)}/${date.getDate()}/${date.getFullYear()}`;
}

const getHHMM = (_date: string) => {
    let date = new Date(_date);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}${ampm}`;
}

const Dashboard = () => {
    const [rawData, setRawData] = React.useState<Idashboard[]>([]);
    const [dashboardDataByPageName, setDashboardDataByPageName] = React.useState<IdataByPageName[]>([]);
    const [reavizData, setReavizData] = React.useState<IdataReaviz[]>([]);
    React.useEffect(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        const fetchData = async () => {
            const fetchedData = await fetchDashboardData();
            const fetchedDataByPageName: { [S: string]: Idashboard[] } = {};
            const finalListByPageName: IdataByPageName[] = [];
            const finalReavizData: IdataReaviz[] = [];
            fetchedData.forEach((data: Idashboard) => {
                if (!fetchedDataByPageName[data.pageName]) {
                    fetchedDataByPageName[data.pageName] = [data];
                }
                else {
                    fetchedDataByPageName[data.pageName].push(data);
                }
            });
            Object.keys(fetchedDataByPageName).forEach(key => {
                finalListByPageName.push({
                    pageName: key,
                    count: fetchedDataByPageName[key].length
                });
                finalReavizData.push({
                    key: key,
                    data: Math.floor((fetchedDataByPageName[key].length / fetchedData.length) * 100)
                });
            });
            setRawData(fetchedData);
            setDashboardDataByPageName(finalListByPageName);
            setReavizData(finalReavizData);
        }
        fetchData();
    }, []);

    return (
        <>
            <GenericTemplate title="Dashboard">
                <h1 className="text-center bd-title mb-5">Page Visits</h1>
                <h2 className="text-center bd-title2">List By Page Name - {dashboardDataByPageName.length}</h2>
                <BarChart
                    height={350}
                    data={reavizData}
                    series={
                        <BarSeries
                            bar={<Bar label={<BarLabel />} />}
                        />
                    }
                />
                <hr className="mt-5 mb-5" />
                <h2 className="text-center bd-title2">Full List - {rawData.length}</h2>
                {
                    <div className="tabelView">
                        <table className="table">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Page</th>
                                    <th scope="col">Env</th>
                                    <th scope="col">IP</th>
                                    <th scope="col">Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    rawData.map((data, i) => {
                                        const day = getDayOfWeek(data.date);
                                        const dmy = getMMDDYYYY(data.date);
                                        const time = getHHMM(data.date);
                                        return (
                                            <tr>
                                                <th scope="row">{i + 1}</th>
                                                <th>{data.pageName}</th>
                                                <td>{data.env}</td>
                                                <td><a href={`https://iplocation.com/?ip=${data.ip}`} target="_blank" rel="noopener noreferrer">{data.ip}</a></td>
                                                <td>{day}, {dmy} - {time}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                }
            </GenericTemplate>
        </>
    );
};

export default Dashboard;
