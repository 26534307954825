import * as React from "react";
import GenericTemplate from "./Template";
import { recordPageVisit, getClientIPAddress } from "../services";

const Home = () => {
    React.useEffect(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        const urlParams = new URLSearchParams(window.location.search);
        const recordPageView = async () => {
            const ip = await getClientIPAddress();
            await recordPageVisit("About", ip);
        };
        if (
            urlParams.get("status") !== "notrack" &&
            window.location.hostname !== "localhost"
        )
            recordPageView();
    }, []);
    return (
        <>
            <GenericTemplate title="About Mike Amara's Blog">
                <div className="text-center">
                    <div className="thumbnail">
                        <img
                            alt="mikeamara.com"
                            src={"img/ma.jpeg"}
                            style={{ width: 100 }}
                        />
                    </div>
                    <a
                        href="https://www.linkedin.com/in/mikeamara/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="fab fa-linkedin large-text text-dark"></i> LinkedIn
                    </a>
                    <a
                        href="https://github.com/mik93mara"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="fab fa-github large-text ml-3 text-dark"></i> GitHub
                    </a>
                    <a
                        href="https://www.npmjs.com/package/@uixpress/webview-post"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="fab fa-npm large-text ml-3 text-dark"></i> NPM Package
                    </a>
                </div>
                <h1 className="text-center bd-title">About Me</h1>
                <h3>Intro</h3>
                <ul>
                    <li>I have been a developer for over 15 years.</li>
                    <li>I worked on many technologies frontend and backend.</li>
                    <li>I specialize in building apps for mobile, web, and desktop.</li>
                    <li>My focus has been on using react, react-native, electron, node, and many other technologies.</li>
                    <li>What am passionate about is teaching and sharing knowledge with others.</li>
                    <li>My real strength is in building teams from the ground up and leading them for success. </li>
                </ul>
                <h3>Experience</h3>
                <ul>
                    <li>Software Engineer Team Lead at BP</li>
                    <li>
                        Helped in the hiring process and building out FTD.com
                        front end team
                    </li>
                    <li>
                        Drove the completion of FTD's first version revamped
                        site for both ftd.com and proflowers.com
                    </li>
                    <li>
                        Built cross-platform apps using Expo.io (mobile, web and
                        desktop)
                    </li>
                    <li>
                        Worked on React, React-native code and Redux
                    </li>
                    <li>
                        Used Electron to build Desktop apps
                    </li>
                </ul>

                <h3 className="mt-5">Skills</h3>
                <ul>
                    <li className="badge badge-pill badge-danger m-1"><i className="fa fa-code small-text mr-1"></i>React</li>
                    <li className="badge badge-pill badge-danger m-1"><i className="fa fa-code small-text mr-1"></i>React hooks</li>
                    <li className="badge badge-pill badge-danger m-1"><i className="fa fa-code small-text mr-1"></i>Redux</li>
                    <li className="badge badge-pill badge-danger m-1"><i className="fa fa-code small-text mr-1"></i>Post-css</li>
                    <li className="badge badge-pill badge-danger m-1"><i className="fa fa-code small-text mr-1"></i>Styled-components</li>
                    <li className="badge badge-pill badge-danger m-1"><i className="fa fa-code small-text mr-1"></i>Sass</li>

                    <li className="badge badge-pill badge-success m-1"><i className="fa fa-mobile small-text mr-1"></i>React Native</li>
                    <li className="badge badge-pill badge-success m-1"><i className="fa fa-mobile small-text mr-1"></i>Expo.io</li>
                    <li className="badge badge-pill badge-success m-1"><i className="fa fa-desktop small-text mr-1"></i>Electron</li>

                    <li className="badge badge-pill badge-dark m-1">Lerna</li>
                    <li className="badge badge-pill badge-dark m-1">Git</li>

                    <li className="badge badge-pill badge-primary m-1"><i className="fa fa-server small-text mr-1"></i>Node</li>
                    <li className="badge badge-pill badge-primary m-1"><i className="fa fa-server small-text mr-1"></i>Express</li>
                    <li className="badge badge-pill badge-primary m-1"><i className="fa fa-server small-text mr-1"></i>Koa</li>
                    <li className="badge badge-pill badge-primary m-1"><i className="fa fa-server small-text mr-1"></i>GraphQL</li>
                    <li className="badge badge-pill badge-primary m-1"><i className="fa fa-server small-text mr-1"></i>Cypress</li>
                    <li className="badge badge-pill badge-primary m-1"><i className="fa fa-server small-text mr-1"></i>Supertest</li>
                    <li className="badge badge-pill badge-primary m-1"><i className="fa fa-server small-text mr-1"></i>Jest</li>
                    <li className="badge badge-pill badge-warning m-1">LDAP</li>
                    <li className="badge badge-pill badge-warning m-1">SAML2.0</li>
                    <li className="badge badge-pill badge-warning m-1">JWT</li>
                    
                </ul>
            </GenericTemplate>
        </>
    );
};

export default Home;
