import * as React from "react";
import Posts from "../posts/index";
import GenericTemplate from "./Template";
import { recordPageVisit, getClientIPAddress } from "../services";

const Home = () => {
    React.useEffect(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        const urlParams = new URLSearchParams(window.location.search);
        const recordPageView = async () => {
            const ip = await getClientIPAddress();
            await recordPageVisit("Home", ip);
        }
        if (urlParams.get('status') !== 'notrack' && window.location.hostname !== "localhost")
            recordPageView();
    }, []);
    return (
        <>
            <GenericTemplate title="Welcome to Mike Amara's Blog">
                <h1 className="text-center bd-title">MA Personal Blog</h1>
                <Posts />
            </GenericTemplate>
        </>
    );
};

export default Home;
