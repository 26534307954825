export interface Isource {
    name: string;
    link: string;
}

export interface Ipost {
    id: string;
    title: string;
    folderName: string;
    description: string;
    tags?: string[];
    comp?: string;
    date?: Date;
    resources?: Isource[];
    author?: string;
}

const posts = [
    {
        id: "2020040101",
        title: "Nock Test",
        folderName: "post-2020-04-01-01-nock",
        description: "the Nock module provides a way to mock api response with custom data. This approach might be needed especially for testing JSON schemas. Here are some examples on how this approach is implemented:",
        tags: ["ExpressJS", "Jest", "Supertest", "Nock", "jest-json-schema"],
        comp: "./posts/2020-04-01-01",
        date: new Date("04-01-2020"),
        resources: [
            {name: "supertest", link: "https://github.com/visionmedia/supertest#readme"},
            {name: "nock", link: "https://github.com/nock/nock#readme"},
            {name: "jest-json-schema", link: "https://github.com/americanexpress/jest-json-schema#readme"}
        ],
        author: "MA",
    },
    {
        id: "2020031501",
        title: "Super Test",
        folderName: "post-2020-03-15-01-supertest",
        description: "A great way to perform e2e testing of the APIs is to use Supertest with Nock. Both of these combined provides a very slick way to test your server APIs. The underlying testing platform that I will be using is jest, but you are free to select any alternative you feel comfortable with. In a nutshell Supertest provides a way to perform the needed API requests to the server using POST/GET, and Nock provides a way to intercept these requests and provide a custom response when needed (Example: testing schemas). Let's look at some examples using jest, supertest and nock.",
        tags: ["ExpressJS", "Jest", "Supertest", "Nock"],
        comp: "./posts/2020-03-15-01",
        date: new Date("03-15-2020"),
        resources: [
            {name: "supertest", link: "https://github.com/visionmedia/supertest#readme"},
            {name: "nock", link: "https://github.com/nock/nock#readme"}
        ],
        author: "MA",
    },
    {
        id: "2020020101",
        title: "Treating a Json object as a Tree",
        folderName: "post-2020-02-01-01-json_tree_structure",
        description:
            'Given string "abc" as input and an existing empty object `const tree = {}`. Write an algorithm to add each character in the string to the empty tree so it would look like:',
        tags: ["interview", "algorithms"],
        comp: "./posts/2020-02-01-01",
        date: new Date("02-01-2020"),
        resources: [],
        author: "MA",
    },
    {
        id: "2020010101",
        title: "Code, Extensions & Blogs",
        folderName: "post-2020-01-01-01-useful_links",
        description:
            "If you ever wondered which vscode extensions, chrome extensions, mock data sites or other personal blogs then check this list out.",
        tags: ["vscode", "Chrome Extensions", "Mock Data - Online", "Blogs"],
        comp: "./posts/2020-01-01-01",
        date: new Date("01-01-2020"),
        resources: [],
        author: "MA",
    },
    {
        id: "2019111001",
        title: "Replace Redux with React Hooks",
        folderName: "post-2019-11-10-01-replace_redux_with_react_hooks",
        description:
            "Have you been using Redux? Do you know that for your next app you don't have to? WOW - this is great news! I am super excited to share with others how that could be done. Here is a quick implementation of a POC app that replaced Redux with React Hooks.",
        tags: ["ReactJS", "Hooks", "Redux"],
        comp: "./posts/2019-11-10-01",
        date: new Date("11-10-2019"),
        resources: [
            {
                name: "Replacing redux with react hooks",
                link:
                    "https://medium.com/octopus-labs-london/replacing-redux-with-react-hooks-and-context-part-1-11b72ffdb533",
            },
        ],
        author: "MA",
    },
    {
        id: "2019101701",
        title: "Custom Hooks for Forms",
        folderName: "post-2019-10-17-01-custom_form_hook",
        description:
            "How to create a custom hook for forms in React? Answering this question will open the door for devs to start thinking about separating functional code from the view, which would help with writing a unit test, and also open the possibilities on how to create custom hooks and when to do that.",
        tags: ["ReactJS", "Hooks"],
        comp: "./posts/2019-10-17-01",
        date: new Date("10-17-2019"),
        resources: [
            {
                name: "React Hooks",
                link: "https://reactjs.org/docs/hooks-intro.html",
            },
        ],
        author: "MA",
    },
    {
        id: "2019101201",
        title: "Download csv file from json data",
        folderName: "post-2019-10-12-01-download_csv_file_from_json_data",
        description:
            "Recently I was asked to write some code where data needed to be downloaded as a CSV file. The data on the backend was a JSON object. The following code did the job of downloading the CSV file as required. Here is the code sample:",
        tags: ["Koa", "server-side", "router", "api", "Server", "Node"],
        comp: "./posts/2019-10-12-01",
        date: new Date("10-12-2019"),
        resources: [
            { name: "Koa", link: "https://github.com/koajs/koa#readme" },
        ],
        author: "MA",
    },
    {
        id: "2019091501",
        title: "Unique collections using Set",
        folderName: "post-2019-09-15-01-unique_collections_using_set",
        description:
            "Set object allows us to store unique data values of any type in a set. This Set object could be then accessed and manipulated using a set of methods such as:",
        tags: ["Javascript", "Set"],
        comp: "./posts/2019-09-15-01",
        date: new Date("09-15-2019"),
        resources: [
            {
                name: "mozilla.org",
                link:
                    "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set",
            },
        ],
        author: "MA",
    },
    {
        id: "2019091201",
        title: "Object keys",
        folderName: "post-2019-09-12-01-object_keys",
        description:
            "The method of Object.keys() coverts an object into an array. A function could be used to iterate through the array where first parameter is the key in each iteration, the second parameter is array of index and third parameter is the index.",
        tags: ["Javascript", "Objects"],
        comp: "./posts/2019-09-12-01",
        date: new Date("09-12-2019"),
        resources: [
            {
                name: "mozilla.org",
                link:
                    "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys",
            },
        ],
        author: "MA",
    },
    {
        id: "2019090701",
        title: "Object assign",
        folderName: "post-2019-09-07-01-object_assign",
        description:
            "Object.assign() is a method used extend objects by copying their properties from one to another. Earlier properties will be overridden by later objects properties.",
        tags: ["Javascript", "Objects"],
        comp: "./posts/2019-09-07-01",
        date: new Date("09-07-2019"),
        resources: [
            {
                name: "mozilla.org",
                link:
                    "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign",
            },
        ],
        author: "MA",
    },
    {
        id: "2019090501",
        title: "Ways to use a Reduce function",
        folderName: "post-2019-09-05-01-ways_to_use_a_Reduce_function",
        description:
            "The reduce() method produces a single output value by executing a reducer function that go over every array element. The second parameter provided is an optional initial value of the reduced output.",
        tags: ["Javascript", "Arrays"],
        comp: "./posts/2019-09-05-01",
        date: new Date("09-05-2019"),
        resources: [
            {
                name: "mozilla.org",
                link:
                    "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/reduce",
            },
        ],
        author: "MA",
    },
    {
        id: "2019090301",
        title: "PostgresQL Join Types",
        folderName: "post-2019-09-03-01-postgresql_join_types",
        description:
            "PostgreSQL is an object-relational database management system. It is open-source and supports a large part of the SQL standard and offers many modern features. This is a quick tutorial for Beginners (Part-III) to learn how to use PostgresQL on the terminal.",
        tags: ["PostgresQL", "terminal", "SQL"],
        comp: "./posts/2019-09-03-01",
        date: new Date("09-03-2019"),
        resources: [
            { name: "postgresql", link: "https://www.postgresql.org/docs/11/" },
        ],
        author: "MA",
    },
    {
        id: "2019090201",
        title: "PostgresQL Basic Commands",
        folderName: "post-2019-09-02-01-postgresql-basic-commands",
        description:
            "PostgreSQL is an object-relational database management system. It is open-source and supports a large part of the SQL standard and offers many modern features. This is a quick tutorial for Beginners (Part-II) to learn how to use PostgresQL on the terminal.",
        tags: ["PostgresQL", "terminal", "SQL"],
        comp: "./posts/2019-09-02-01",
        date: new Date("09-02-2019"),
        resources: [
            { name: "postgresql", link: "https://www.postgresql.org/docs/11/" },
            {
                name: "postgresql - functions-aggregate",
                link:
                    "https://www.postgresql.org/docs/11/functions-aggregate.html",
            },
            {
                name: "postgresql - datatype-datetime",
                link:
                    "https://www.postgresql.org/docs/11/datatype-datetime.html",
            },
        ],
        author: "MA",
    },
    {
        id: "2019090101",
        title: "PostgresQL Setup with ZSH & Some SQL Commands",
        folderName:
            "post-2019-09-01-01-postgresql_setup_with_zsh_some commands",
        description:
            "PostgreSQL is an object-relational database management system. It is open-source and supports a large part of the SQL standard and offers many modern features. This is a quick tutorial for Beginners (Part-I) to learn how to use PostgresQL on the terminal.",
        tags: ["PostgresQL", "terminal", "SQL", "iTerm2"],
        comp: "./posts/2019-09-01-01",
        date: new Date("09-01-2019"),
        resources: [
            { name: "postgresql", link: "https://www.postgresql.org/docs/11/" },
        ],
        author: "MA",
    },
    {
        id: "2019083002",
        title: "React Hooks",
        folderName: "post-2019-08-30-02-react_hooks",
        description:
            "React Hooks were added to React 16.8 release. The change allowed devs to add state and other existing react features to functional components. This change opened the door for functional components to be widely used and to replace the need to create class components. The good thing about this change is that it doesn't replace your knowledge and understanding of existing React concepts from class components, but rather they give you a new API to the same React concepts you already know to be used in functional components.",
        tags: ["React"],
        comp: "./posts/2019-08-30-02",
        date: new Date("08-30-2019"),
        resources: [
            {
                name: "React",
                link: "https://reactjs.org/docs/hooks-intro.html",
            },
        ],
        author: "MA",
    },
    {
        id: "2019083001",
        title: "Linking Dependency Modules",
        folderName: "post-2019-08-30-01-linking_dependencies_modules",
        description:
            "Sometimes you might be in the following scenario: You have an app that you are developing, and a change is needed to be performed locally to a dependency module that your app uses which you developed yourself. When this happens you will need to locally link your local module to your local app.",
        tags: ["npm"],
        comp: "./posts/2019-08-30-01",
        date: new Date("08-30-2019"),
        resources: [{ name: "npm", link: "https://docs.npmjs.com/cli/link" }],
        author: "MA",
    },
    {
        id: "2019082901",
        title: "Interview Questions",
        folderName: "post-2919-08-29-01-interview_questions",
        description:
            "Questions you might need for interviews. Topics covered are: Javascript, PHP, Sql and more.",
        tags: ["ReactJS", "React-native", "Javascript", "PHP", "Node"],
        comp: "./posts/2019-08-29-01",
        date: new Date("08-29-2919"),
        resources: [],
        author: "MA",
    },
    {
        id: "2019082101",
        title: "Use async/await with Array.map",
        folderName: "post-2019-08-21-01-Use_async_await_with_Array_map",
        description:
            "If you have tried to create a loop and use async/await with it similar to this code:",
        tags: ["es6", "async", "promise", "loop"],
        comp: "./posts/2019-08-21-01",
        date: new Date("08-21-2019"),
        resources: [
            {
                name: "medium",
                link:
                    "https://medium.com/@ian.mundy/async-map-in-javascript-b19439f0099",
            },
            {
                name: "mozilla.org",
                link:
                    "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises",
            },
        ],
        author: "MA",
    },
    {
        id: "2019081701",
        title: "KoaJS - Framework for NodeJS",
        folderName: "post-2019-08-17-01-KoaJS_Framework_for_NodeJS",
        description:
            "Koa was created by the creators of expressJS with more modern uses es6. It uses enhanced req/res and HTTP calls, and similar in syntax to expressJS. It is considered a widely used back-en/server-side framework for node, that has a smaller footprint than nodes and expressjs, and it has a thinner and better middleware. Koa can extend the framework with added modules, and it uses generators and async/await. Uses req/res object on top of the standard vanillaJS quest/response.",
        tags: ["koa", "server-side", "router", "api", "templates"],
        comp: "./posts/2019-08-17-01",
        date: new Date("08-17-2019"),
        resources: [
            { name: "Koa", link: "https://koajs.com/" },
            { name: "github - KoaJS", link: "https://github.com/koajs" },
            {
                name: "github Demo - My ToDo App",
                link: "https://github.com/mik93mara/koa",
            },
        ],
        author: "MA",
    },
    {
        id: "2019072701",
        title: "Z-Shell (ZSH)",
        folderName: "post-2019-07-27-01-Z_Shell_ZSH",
        description:
            "ZSH, also called the Z shell, is an extended version of the Bourne Shell (sh), with plenty of new features, and support for plugins and themes. Since it’s based on the same shell as Bash, ZSH has many of the same features, and switching over is a breeze. Here are the steps needed to get it up and running:",
        tags: ["iTerm2", "terminal", "shell"],
        comp: "./posts/2019-07-27-01",
        date: new Date("07-27-2019"),
        resources: [
            {
                name: "iterm2",
                link: "https://www.iterm2.com/documentation.html",
            },
            {
                name: "github - oh-my-zsh",
                link:
                    "https://github.com/robbyrussell/oh-my-zsh/wiki/Installing-ZSH",
            },
            {
                name: "github - oh-my-zsh plugins",
                link:
                    "https://github.com/robbyrussell/oh-my-zsh/tree/master/plugins",
            },
            {
                name: "howtogeek.com",
                link:
                    "https://www.howtogeek.com/362409/what-is-zsh-and-why-should-you-use-it-instead-of-bash/",
            },
            {
                name: "youtube class session",
                link: "https://www.youtube.com/watch?v=MSPu-lYF-A8",
            },
            {
                name: "npm-run.plugin",
                link: "https://github.com/akoenig/npm-run.plugin.zsh",
            },
        ],
        author: "MA",
    },
    {
        id: "2019072601",
        title: "Helpful Terminal Commands for Devs on Macs",
        folderName: "post-2019-07-26-01-Quick_Terminal_Commands_for_Devs",
        description:
            "Every time I start working on a new project or setting up a new mac I always run through the same issues on the Mac. To save myself and others time I put together a list of these issues and how to resolve them. Here is the list of problems:",
        tags: ["command line", "terminal", "CORS", "env variables"],
        comp: "./posts/2019-07-26-01",
        date: new Date("07-26-2019"),
        resources: [
            {
                name: "stackoverflow.com",
                link:
                    "https://stackoverflow.com/questions/3855127/find-and-kill-process-locking-port-3000-on-mac",
            },
            {
                name: "imore.com",
                link:
                    "https://www.imore.com/how-edit-your-macs-hosts-file-and-why-you-would-want",
            },
            {
                name: "alfilatov.com",
                link: "https://alfilatov.com/posts/run-chrome-without-cors/",
            },
            {
                name: "medium.com",
                link:
                    "https://medium.com/@kingnand.90/what-is-the-difference-between-bash-profile-and-bashrc-d4c902ac7308",
            },
        ],
        author: "MA",
    },
    {
        id: "2019072302",
        title: "Programming Case Types",
        folderName: "post-2019-07-23-02-Programming_Case_Types",
        description:
            "The organization is critical when it comes to programming. And part of that organization defines a set of rules and guidelines for team members to follow. Some of these rules are dedicated to choosing the case types to be used for folder/file names, frontend, backend, and database variable/code standards. Therefore knowing what types are common and to understand when is each one is used is critical especially in team collaboration. Without any further delay let’s look at each type, some examples and when it is used. The most common case types are:",
        tags: ["standards"],
        comp: "./posts/2019-07-23-02",
        date: new Date("07-23-2019"),
        resources: [
            {
                name: "wikipedia - camel case",
                link: "https://en.wikipedia.org/wiki/Camel_case",
            },
            {
                name: "wikipedia - snake case",
                link: "https://en.wikipedia.org/wiki/Snake_case",
            },
            {
                name: "quora.com",
                link:
                    "https://www.quora.com/What-is-the-difference-between-Pascal-Case-and-Camel-Case",
            },
            {
                name: "toolpage.com",
                link: "https://en.toolpage.org/tool/kebabcase",
            },
        ],
        author: "MA",
    },
    {
        id: "2019072301",
        title: "Cross Platform Apps",
        folderName: "post-2019-07-23-01-Cross_Platform_Apps",
        description:
            "I was asked by one of my clients to rebuild their desktop app using the same code for web, desktop, and mobile. This requirement made me start researching what was available in the market. To be honest with you at the beginning I got impressed with nativescript. Nativescript is an open-source framework for building truly native mobile apps with Angular, Vue.js, TypeScript, or pure JavaScript.",
        tags: ["resub", "reactxp", "mobile"],
        comp: "./posts/2019-07-23-01",
        date: new Date("07-23-2019"),
        resources: [
            { name: "nativescript.org", link: "https://www.nativescript.org/" },
            {
                name: "youtube - NativeScript",
                link:
                    "https://www.youtube.com/watch?v=KNgx3JAyCtk&feature=youtu.be",
            },
            {
                name: "github - Reactxp",
                link: "https://microsoft.github.io/reactxp/",
            },
            {
                name: "github - Resub",
                link: "https://github.com/microsoft/ReSub",
            },
        ],
        author: "MA",
    },
    {
        id: "2019071403",
        title: "Deploy your changes locally",
        folderName: "post-2019-07-14-03-Deploy_your_changes_locally",
        description:
            "If you use Git and you need to upload your files to an FTP server, Git-ftp can save you some time and bandwidth by uploading only those files that changed since the last upload. It keeps track of the uploaded files by storing the commit id in a log file on the server. It uses Git to determine which local files have changed.",
        tags: ["web development"],
        comp: "./posts/2019-07-14-03",
        date: new Date("07-14-2019"),
        resources: [
            {
                name: "github - git-ftp",
                link: "https://github.com/git-ftp/git-ftp",
            },
        ],
        author: "MA",
    },
    {
        id: "2019071402",
        title: "Configure location where npm will install global modules",
        folderName:
            "post-2019-07-14-02-Configure_location_where_npm_will_install_global_modules",
        description:
            "To minimize the chance of permissions errors on the Mac, you can configure npm to use a different directory. In this example, it will be a hidden directory on your home folder.",
        tags: ["npm", "node"],
        comp: "./posts/2019-07-14-02",
        date: new Date("07-14-2019"),
        resources: [
            {
                name: "npmjs.com",
                link:
                    "https://docs.npmjs.com/resolving-eacces-permissions-errors-when-installing-packages-globally",
            },
        ],
        author: "MA",
    },
    {
        id: "2019071401",
        title: "Observer vs Pub-Sub pattern",
        folderName: "post-2019-07-14-01-Observer_vs_Pub_Sub_pattern",
        description:
            "In the ‘Publisher-Subscriber’ pattern, The publisher and subscriber don’t know about the existence of one another. There is a third component, called broker or message broker or event bus, which is known by both the publisher and subscriber, which filters all incoming messages and distributes them accordingly. In other words, pub-sub is a pattern used to communicate messages between different system components without these components knowing anything about each other’s identity.",
        tags: ["design pattern"],
        comp: "./posts/2019-07-14-01",
        date: new Date("07-14-2019"),
        resources: [
            {
                name: "hackernoon.com",
                link:
                    "https://hackernoon.com/observer-vs-pub-sub-pattern-50d3b27f838c",
            },
        ],
        author: "MA",
    },
    {
        id: "2019071301",
        title: "ES6",
        folderName: "post-2019-07-13-01-ES6",
        description:
            "ES6 refers to version 6 of the ECMA Script programming language. ECMA Script is the standardized name for JavaScript, and version 6 is the next version after version 5, which was released in 2011. It is a major enhancement to the JavaScript language and adds many more features intended to make large-scale software development easier.",
        tags: ["es6"],
        comp: "./posts/2019-07-13-01",
        date: new Date("07-13-2019"),
        resources: [
            {
                name: "makeuseof.com - What is ES6?",
                link:
                    "https://www.makeuseof.com/tag/es6-javascript-programmers-need-know/",
            },
            {
                name: "makeuseof.com - What is Javascript?",
                link:
                    "https://www.makeuseof.com/tag/what-is-javascript-and-can-the-internet-exist-without-it/",
            },
        ],
        author: "Mike Amara",
    },
    {
        id: "2019071302",
        title: "Typescript",
        folderName: "post-2019-07-13-02-Typescript",
        description:
            "TypeScript is a superset of JavaScript which primarily provides optional static typing, classes, and interfaces. One of the big benefits is to enable IDEs to provide a richer environment for spotting common errors as you type the code. To get an idea of what I mean, watch Microsoft's introductory video on the language. For a large JavaScript project, adopting TypeScript might result in more robust software, while still being deployable where a regular JavaScript application would run. It is an open-source, but you only get the clever Intellisense as you type if you use a supported IDE. Initially, this was only Microsoft's Visual Studio (also noted in a blog post from Miguel de Icaza). These days, other IDEs offer TypeScript support too.",
        tags: ["typescript"],
        comp: "./posts/2019-07-13-02",
        date: new Date("07-13-2019"),
        resources: [
            {
                name: "Typescript Online Editor",
                link: "https://typescript-play.js.org",
            },
            {
                name: "typescriptlang.org",
                link: "https://www.typescriptlang.org/",
            },
            {
                name: "React module - All utility Flow types",
                link: "https://flow.org/en/docs/react/types/",
            },
            {
                name: "TypeScript Doc",
                link:
                    "https://basarat.gitbooks.io/typescript/docs/why-typescript.html",
            },
        ],
        author: "MA",
    },
];

const shuffle = (p: Ipost[]) => {
    return p.sort(() => Math.random() - 0.5);
};

export default shuffle(posts);
