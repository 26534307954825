import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, RouteProps } from "react-router-dom";
import posts from "./posts/posts";
import Home from "./pages/Home";
import About from "./pages/About";
import Dashboard from "./pages/dashboard";
// import DevsBank from "./pages/DevBank";
// import Intro from "./pages/screening";
// import Page1 from "./pages/screening/01-generalQuestions";
// import Page2 from "./pages/screening/02-evaluate";
// import Page3 from "./pages/screening/03-completeCode";
// import Page4 from "./pages/screening/04-rewriteCode";

import "./styles.css";

function WaitingComponent(Component: any) {
    return (props: RouteProps) => {
        return (
            <Suspense
                fallback={
                    <div className="init-spinner d-flex mt-5 justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <span className="h3">&nbsp;Loading Site...</span>
                    </div>
                }
            >
                <Component {...props} />
            </Suspense>
        );
    };
}

const Routes = () => {
    return (
        <>
            <Router>
                <div className="container-fluid">
                    <Route exact path="/" component={Home} />
                    <Route exact path="/about" component={About} />
                    <Route exact path="/dashboard" component={Dashboard} />
                    {/* <Route exact path="/devsbank" component={DevsBank} />
                    <Route exact path="/screening/" component={Intro} />
                    <Route exact path="/screening/page1" component={Page1} />
                    <Route exact path="/screening/page2" component={Page2} />
                    <Route exact path="/screening/page3" component={Page3} />
                    <Route exact path="/screening/page4" component={Page4} /> */}
                    {posts.map((post) => {
                        const comp = lazy(() => import(`${post.comp}`));
                        const compPath = WaitingComponent(comp);
                        return (
                            <Route
                                key={post.id}
                                path={`/${post.folderName}/`}
                                component={compPath}
                            />
                        );
                    })}
                </div>
            </Router>
        </>
    );
};

export default Routes;
