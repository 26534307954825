import * as React from "react";
import { Helmet } from "react-helmet";
import Nav from "../components/Nav";
import Footer from "../components/Footer";

interface Iprops {
    title: string;
    author?: string;
    description?: string;
    keywords?: string[];
    date?: Date;
};

const GenericTemplate: React.FunctionComponent<Iprops> = (props) => {
    const {
        title = "Welcome to Mike Amara's Blog",
        author = "Mike Amara",
        description = "",
        keywords = [],
        date = new Date()
    } = props;
    const url = window.location.href;
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="author" content={author} />
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords.join(",")} />
                <meta name="date" content={date.toString()} />

                <meta property="og:type" content="website" />
                <meta property="og:title" content={title} />
                <meta name="description" property="og:description" content={description} />
                <meta property="og:image" content="https://www.mikeamara.com/img/default.png" />
                <meta property="og:url" content={url} />

                <meta name="twitter:card" content="https://www.mikeamara.com/img/default.png" />
                <meta name="twitter:site" content="@uixpress" />
                <meta name="twitter:domain" content={url} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
            </Helmet>
            <Nav />
            <div className="content">{props.children}</div>
            <Footer />
        </>
    );
};

export default GenericTemplate;
